export const LanguageMixin = {
  methods: {
    /**
     * Ex: para pt-BR, retorna pt.
     */
    getCurrentLanguageCode() {
      const localLanguageCode = localStorage.lang;
      if (localLanguageCode) {
        return localLanguageCode;
      } else {
        //browser language
        var language = navigator.language;
        return language.substring(0, 2);
      }
    },
    /**
     * @languageCode: ex: pt
     */
    configureLanguage(languageCode) {
      // console.log("changeLanguage=" + languageCode);

      this.$i18n.locale = languageCode;
      this.$vuetify.lang.current = languageCode;
      this.$moment.locale(languageCode);
    },
  },
};
