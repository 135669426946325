<template>
  <v-footer class="d-print-none" padless style="border-top:1px solid #cccccc">
    <v-row justify="space-between" no-gutters class="white">
      <v-col offset-sm="2" offset-md="3" offset-lg="4" cols="5 lg-7" class="pl-5 py-3 text-center" align-self="center">
        {{ new Date().getFullYear() }} — {{ footerText}} —
        <router-link :to="{ name: 'version' }">{{ $t("version") }}</router-link>
      </v-col>
      <v-col class="pt-3 pb-1 pr-5 text-right">
        <a href="https://www.serpro.gov.br/" target="new">
          <img src="../assets/img/logo-serpro.png" height="30" />
        </a>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  computed: {
    footerText() {
      let sisbraip = window.location.host.includes("sisbraip");
      return sisbraip ? "SISBRAIP" : "Embarque + Seguro"
    }
  }
};
</script>

<style lang="scss" scoped></style>
