import AxiosHelper from "@/helpers/AxiosHelper.js";

export default {
  markAsRead(alertId) {
    return AxiosHelper.inspectionClient.post(`/v1/alerts/read/${alertId}`);
  },
  markAsUnread(alertId) {
    return AxiosHelper.inspectionClient.post(`/v1/alerts/unread/${alertId}`);
  },
  switchPinAlert(alertId) {
    return AxiosHelper.inspectionClient.post(`/v1/alerts/switch-pin/${alertId}`);
  },
  getUserAlerts(form) {
    return AxiosHelper.inspectionClient.post(`/v1/alerts`, form);
  },
  getPassengerAlerts(form) {
    return AxiosHelper.inspectionClient.post(`/v1/alerts/passenger`, form);
  },
  getVUAlerts(form) {
    return AxiosHelper.inspectionClient.post(`/v1/alerts/vu`, form);
  },
  deleteAlerts(ids) {
    return AxiosHelper.inspectionClient.delete(
      "/v1/alerts/deleteAlerts",
      {
        params: {
          ids,
        },
      }
    );
  },
};
