<template>
  <v-app>
    <v-main class="grey lighten-4">
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Sisbraip</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-alert type="warning">404 - {{$t('pageNotFound')}}</v-alert>
                <div v-if="$route.query.url">
                  <div>{{$route.query.url}}</div>
                  <div class="mt-2 text-center">
                    <v-btn small class="ml-2" @click="goBack(2)">Voltar</v-btn>
                  </div>
                </div>
                <div v-else>
                  <div>{{getCurrentUrl()}}</div>
                  <div class="mt-2 text-center">
                    <v-btn small class="ml-2" @click="goBack(1)">Voltar</v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  methods: {
    goBack(index) {
      window.history.length > index
        ? this.$router.go(-index)
        : this.$router.push({ name: "/" });
    },
    getCurrentUrl() {
      return window.location.href;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>