import Vue from "vue";
import App from "./App.vue";
import "./assets/css/app.css";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import router from "@/router";
import VueProgressBar from "vue-progressbar";

Vue.config.productionTip = false;

Vue.use(VueProgressBar);

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
