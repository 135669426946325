import UserService from "@/services/UserService.js";
import AlertService from "@/services/AlertService.js";

const BASE_URL = process.env.VUE_APP_SISBRAIP_INSPECTION_URL;

class UserAlerts {
    alerts = [];
    listeners = [];
    conected = false;
    count = 0;
    loading = true;

    register(listener){
        this.listeners.push(listener);
        if (!this.loading) {
            listener(this.count);
        }
    }

    async allowAlerts(){
        return await UserService.showAlert();
    }

    add(alert){
        this.alerts.unshift(alert);
        this.listeners.forEach(listener => {
            listener(this.alerts);
        });
    }

    set(count) {
        this.count = count;
        this.notifyListeners();
    }

    inc() {
        this.count++;
        this.notifyListeners();
    }

    delete(amount){
        this.count -= amount;
        this.notifyListeners();
    }

    dec() {
        this.count--;
        this.notifyListeners();
    }

    notifyListeners() {
        this.listeners.forEach(listener => {
            listener(this.count);
        });
    }

    markAsRead(alert){
        if(!alert.read){
            alert.read = true;
            AlertService.markAsRead(alert.id);
        }
    }

    subscribeToEvents() {
        if(this.conected){
            return;
        }
        this.conected = true;
        this.loading = true;
        UserService.getTemporaryToken()
        .then((tokenData) => {
        let token = tokenData.data;
        this.eventSource = new EventSource(
            BASE_URL + "/v1/alerts" + "?token=" + token
        );

        this.eventSource.onopen = () => {
            this.loading = false;
        };

        this.eventSource.addEventListener("message", (e) => {
            // let pushEvent = JSON.parse(e.data);
            let pushEvent = e.data;
            try {
                let objEvent = JSON.parse(pushEvent);
                pushEvent = objEvent;
            } catch {
                //event is not a json
            }
            if (pushEvent == "inc") {
                this.inc();
            } else if (pushEvent?.operation == 'delete') {
                this.delete(pushEvent.alertCount);
            } else {
                this.set(pushEvent);
            }
            //this.add(pushEvent);
        });

        this.eventSource.onerror = (evt) => {
            console.log("Error");
            console.log(evt);
            if (this.eventSource.readyState == 2) {
            //Handle 403 errors - must get new temporary token
            this.eventSource.close();
            this.subscribeToEvents();
            }
        };
        })
    .catch((e) => (this.loading = false));
    }
}

const userAlerts = new UserAlerts();
export default userAlerts;