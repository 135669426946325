export default [
  {
    path: "login",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
  },
  {
    path: "login-return",
    name: "login-return",
    component: () => import("@/views/auth/LoginReturn.vue"),
  },
  {
    path: "logout-return",
    name: "logout-return",
    component: () => import("@/views/auth/LogoutReturn.vue"),
  },
];
