export default [
  {
    path: "list",
    name: "list-billing",
    component: () => import("@/views/admin/billing/ListBilling.vue")    
  },
  {
    path: "add",
    name: "add-billing",
    component: () => import("@/views/admin/billing/AddBilling.vue")
  },
  {
    path: "edit",
    name: "edit-billing",
    component: () => import("@/views/admin/billing/EditBilling.vue")    
  }
];