export default [
  {
    path: "listHelp",
    name: "list-help",
    props: route => ({ enableEdit: route.query.enableEdit }),
    component: () => import("@/views/help/ListHelp.vue"),
  },
  {
    path: "editHelp",
    name: "edit-help",
    component: () => import("@/views/help/EditHelp.vue"),
  }
];
