<template>
  <div>
    <v-card class="mt-2">
      <v-card-subtitle class="pb-0">
        <div
          class="title pa-2 blue--text text--darken-4"
        >Você não possui um perfil de acesso ao Sisbraip.</div>
        <v-divider></v-divider>
      </v-card-subtitle>
      <v-card-text class="ma-2">
        <div>
          Solicite o acesso ao gestor do Sisbraip no seu órgão.
          <ul>
          <li
            class="font-weight-bold"
          >Anvisa: suporte-sisbraip@anvisa.gov.br</li>
          <li
            class="font-weight-bold"
          >Polícia Federal: suporte-sisbraip@pf.gov.br</li>
          <li
            class="font-weight-bold"
          >Vigiagro: vigiagro@agricultura.gov.br</li>
          </ul>
        </div>
        <div>Informe CPF e nome completo.</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>
