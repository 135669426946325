const jwtDecode = require("jwt-decode");

class UserTokenHelper {
  USER_TOKEN = "user-token";
  RETURN_URL = "return-url";

  saveUserToken(token) {
    localStorage.setItem("user-token", token);
  }
  getUserToken() {
    return localStorage.getItem(this.USER_TOKEN);
  }
  removeUserToken() {
    localStorage.removeItem(this.USER_TOKEN);
    localStorage.removeItem("organizationData");
  }
  getUserName() {
    const user = this.getUser();
    if (user) {
      return user.full_name;
    }
    return "";
  }
  getUserDocument() {
    const user = this.getUser();
    if (user) {
      return user.user_name;
    }
    return "";
  }
  getUser() {
    const token = this.getUserToken();
    if (token) {
      return jwtDecode(token);
    }
    return null;
  }
  getUserURL() {
    const user = this.getUser();
    if (user) {
      return user.url;
    }
  }

  hasValidToken() {
    let valid = false;
    let user = this.getUser();
    if (user) {
      let exp = new Date();
      exp.setTime(user.exp * 1000);
      if (new Date() < exp) {
        valid = true;
      }
    }
    return valid;
  }

  saveReturnUrl(fullPath) {
    localStorage.setItem(this.RETURN_URL, fullPath);
  }
  getReturnUrl() {
    let returnUrl = localStorage.getItem(this.RETURN_URL);
    localStorage.removeItem(this.RETURN_URL);
    return returnUrl;
  }
}

export default new UserTokenHelper();
