<template>
  <v-list dense>
    <template v-for="menu in menuItens">
      <!-- itens de menu com filhos -->
      <v-list-group
        v-if="menu.children"
        :key="menu.text"
        v-model="menu.model"
        :prepend-icon="menu.model ? menu.icon : menu['icon-alt']"
        active-class="active-menu-item"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-title>
            <!-- pai -->
            {{ menu.text }}
          </v-list-item-title>
          <v-list-item-icon v-if="menu.hint">
            <v-tooltip  bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-text="'mdi-help-circle'" v-bind="attrs" v-on="on" />
              </template>
              <span>{{menu.hint}}</span>
            </v-tooltip>
          </v-list-item-icon>
        </template>
        <v-list-item
          v-for="(child, i) in menu.children"
          :key="i"
          link
          :to="{ name: child.link }"
          active-class="active-menu-item"
        >
         
          <v-list-item-action v-if="child.icon" class="mr-2">
            <v-icon>{{ child.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <!-- filho -->
              {{ child.text }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="child.hint != null">
            <v-tooltip  bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-text="'mdi-help-circle'" v-bind="attrs" v-on="on" />
              </template>
              <span>{{child.hint}}</span>
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <!-- demais itens de menu -->
      <v-list-item v-else :key="menu.text" link :to="{ name: menu.link, query: menu.query }">
        <v-list-item-action class="mr-4">
          <v-icon>{{ menu.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ menu.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  props: {
    menuItens: Array,
  },
};
</script>

<style lang="scss">
.v-list-group__header__append-icon {
  margin-left: 0px !important;
  min-width: 25px !important;
}

.v-list-group__header__prepend-icon {
  margin-right: 16px !important;
}
.v-list-group__items > .v-list-item {
  padding-left: 32px !important;
}
</style>


