import AxiosHelper from "@/helpers/AxiosHelper.js";

export default {
  authorize() {
    return AxiosHelper.authClient.get("/v1/login/web/authorize-url");
  },
  token(form) {
    return AxiosHelper.authClient.post("/v1/login/web/token", form);
  },
  myAccount() {
    return AxiosHelper.authClient.get("/v1/login/web/gov-br-url");
  },
  logout() {
    return AxiosHelper.authClient.get("/v1/login/web/logout-url");
  },
};
