import moment from "moment";
import Vue from "vue";
import VueMoment from "vue-moment";
import en from "vuetify/es5/locale/en";
import es from "vuetify/es5/locale/es";
import pt from "vuetify/es5/locale/pt";
import Vuetify, { VSnackbar, VBtn, VIcon } from "vuetify/lib";
import VuetifyToast from "vuetify-toast-snackbar";

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});

// pass moment to work locale change
Vue.use(VueMoment, { moment });

Vue.component("App", {});

const vuetify = new Vuetify({
  lang: {
    locales: { pt, en, es },
  },
});

export default vuetify;

Vue.use(VuetifyToast, {
  $vuetify: vuetify.framework,
  y: "top",
  showClose: true,
  closeIcon: "mdi-close",
  timeout: 30000,
});