<template>
  <div id="app">
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import { LanguageMixin } from "@/mixins/LanguageMixin.js";

export default {
  mixins: [LanguageMixin],

  created() {
    const currentLanguageCode = this.getCurrentLanguageCode();
    this.configureLanguage(currentLanguageCode);
  }
};
</script>
