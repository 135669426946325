import AxiosHelper from "@/helpers/AxiosHelper.js";

export default {
  searchUsers(form) {
    return AxiosHelper.authClient.post(`/v1/users/search`, form);
  },
  exportUsers(form) {
    return AxiosHelper.authClient.post(`/v1/users/export`, form, {
      responseType: "arraybuffer",
    });
  },
  addUser(form) {
    return AxiosHelper.authClient.post(`/v1/users`, form);
  },
  importUser(form) {
    return AxiosHelper.authClient.post(`v1/users/import`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  changeActiveUser(userId) {
    const config = { params: { id: userId } };
    return AxiosHelper.authClient.delete(`/v1/users`, config);
  },
  updateUser(form) {
    return AxiosHelper.authClient.put(`/v1/users`, form);
  },
  updateAirports(form) {
    return AxiosHelper.authClient.put(`/v1/users/airports`, form);
  },
  getUser(userId) {
    return AxiosHelper.authClient.get(`/v1/users/${userId}`);
  },
  currentUser() {
    return AxiosHelper.authClient.get(`v1/users/current`);
  },
  showAlert() {
    return AxiosHelper.authClient.get(`v1/users/alerts`);
  },
  getUsersWithoutOrganization() {
    return AxiosHelper.authClient.get(`v1/users/no-organization`);
  },
  getUserAirports() {
    return AxiosHelper.webClient.get(`v1/airports/ofUser`);
  },
  getTemporaryToken() {
    return AxiosHelper.authClient.post(`v1/login/web/temporaryToken`);
  },
  listUsers(form) {
    return AxiosHelper.authClient.post(`/v1/users/list`, form);
  },
};
