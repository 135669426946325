import InspectionPanelOptions from "@/views/inspection/inspection-panel/InspectionPanelOptions.js";
import InspectionTypes from "@/views/inspection/inspection-panel/InspectionTypes.js";

export default [
  {
    path: "rule/list",
    name: "list-rule",
    component: () => import("@/views/inspection/rules/ListRule.vue"),
  },
  {
    path: "rule/add",
    name: "add-rule",
    component: () => import("@/views/inspection/rules/AddRule.vue"),
  },
  {
    path: "rule/edit/:id",
    name: "edit-rule",
    component: () => import("@/views/inspection/rules/EditRule.vue"),
  },
  {
    path: "rule/copy/:id",
    name: "copy-rule",
    component: () => import("@/views/inspection/rules/CopyRule.vue"),
  },
  {
    path: "category/list",
    name: "category-list",
    component: () => import("@/views/inspection/category/ListCategory.vue"),
  },
  {
    path: "category/add",
    name: "category-add",
    component: () => import("@/views/inspection/category/AddCategory.vue"),
  },
  {
    path: "category/addParent",
    name: "category-addParent",
    component: () =>
      import("@/views/inspection/category/AddParentCategory.vue"),
  },
  {
    path: "category/edit",
    name: "edit-category",
    component: () => import("@/views/inspection/category/EditCategory.vue"),
  },
  {
    path: "work-teams/list",
    name: "work-teams-list",
    component: () => import("@/views/inspection/work-teams/ListWorkTeams.vue"),
  },
  {
    path: "work-teams/add",
    name: "work-teams-add",
    component: () => import("@/views/inspection/work-teams/AddWorkTeam.vue"),
  },
  {
    path: "work-teams/edit/:id",
    name: "work-teams-edit",
    component: () => import("@/views/inspection/work-teams/EditWorkTeam.vue"),
  },
  {
    path: "restriction-list/list",
    name: "restriction-list",
    component: () =>
      import("@/views/inspection/restriction-list/ListRestriction.vue"),
  },
  {
    path: "restriction-list/add",
    name: "add-restriction",
    component: () =>
      import("@/views/inspection/restriction-list/AddRestriction.vue"),
  },
  {
    path: "restriction-list/edit/:id",
    name: "edit-restriction",
    component: () =>
      import("@/views/inspection/restriction-list/EditRestriction.vue"),
  },

  {
    path: "international",
    name: "international",
    props: { fiscalizationType: InspectionTypes.INTERNATIONAL },
    component: () =>
      import("@/views/inspection/inspection-panel/InspectionPanel.vue"),
    children: [
      {
        path: "arrivals-flights",
        name: "international-arrivals-flights",
        props: {
          mode: InspectionPanelOptions.INTERNATIONAL_ARRIVALS,
          labelOppositeAirport: "originAirport",
          labelFlightDate: "ruleArrival",
        },
        component: () =>
          import(
            "@/views/inspection/inspection-panel/InspectionPanelFlights.vue"
          ),
      },
      {
        path: "departures-flights",
        name: "international-departures-flights",
        props: {
          mode: InspectionPanelOptions.INTERNATIONAL_DEPARTURES,
          labelOppositeAirport: "destinationAirport",
          labelFlightDate: "ruleDeparture",
        },
        component: () =>
          import(
            "@/views/inspection/inspection-panel/InspectionPanelFlights.vue"
          ),
      },
      {
        path: "arrivals-passengers",
        name: "international-arrivals-passengers",
        props: {
          mode: InspectionPanelOptions.INTERNATIONAL_ARRIVALS,
          labelOppositeAirport: "originAirport",
          labelFlightDate: "ruleArrival",
        },
        component: () =>
          import(
            "@/views/inspection/inspection-panel/InspectionPanelPassengers.vue"
          ),
      },
      {
        path: "departures-passengers",
        name: "international-departures-passengers",
        props: {
          mode: InspectionPanelOptions.INTERNATIONAL_DEPARTURES,
          labelOppositeAirport: "destinationAirport",
          labelFlightDate: "ruleDeparture",
        },
        component: () =>
          import(
            "@/views/inspection/inspection-panel/InspectionPanelPassengers.vue"
          ),
      },
    ],
  },
  {
    path: "domestic",
    name: "domestic",
    props: { fiscalizationType: InspectionTypes.DOMESTIC },
    component: () =>
      import("@/views/inspection/inspection-panel/InspectionPanel.vue"),
    children: [
      {
        path: "arrivals-flights",
        name: "domestic-arrivals-flights",
        props: {
          mode: InspectionPanelOptions.DOMESTIC_ARRIVALS,
          labelOppositeAirport: "originAirport",
          labelFlightDate: "ruleArrival",
        },
        component: () =>
          import(
            "@/views/inspection/inspection-panel/InspectionPanelFlights.vue"
          ),
      },
      {
        path: "departures-flights",
        name: "domestic-departures-flights",
        props: {
          mode: InspectionPanelOptions.DOMESTIC_DEPARTURES,
          labelOppositeAirport: "destinationAirport",
          labelFlightDate: "ruleDeparture",
        },
        component: () =>
          import(
            "@/views/inspection/inspection-panel/InspectionPanelFlights.vue"
          ),
      },
      {
        path: "arrivals-passengers",
        name: "domestic-arrivals-passengers",
        props: {
          mode: InspectionPanelOptions.DOMESTIC_ARRIVALS,
          labelOppositeAirport: "originAirport",
          labelFlightDate: "ruleArrival",
        },
        component: () =>
          import(
            "@/views/inspection/inspection-panel/InspectionPanelPassengers.vue"
          ),
      },
      {
        path: "departures-passengers",
        name: "domestic-departures-passengers",
        props: {
          mode: InspectionPanelOptions.DOMESTIC_DEPARTURES,
          labelOppositeAirport: "destinationAirport",
          labelFlightDate: "ruleDeparture",
        },
        component: () =>
          import(
            "@/views/inspection/inspection-panel/InspectionPanelPassengers.vue"
          ),
      },
    ],
  },

  {
    path: "ocurrence/add/:id",
    name: "add-occurrence",
    component: () => import("@/views/inspection/ocurrence/AddOccurrence.vue"),
  },
  {
    path: "ocurrence/edit/:id",
    name: "edit-occurrence",
    component: () => import("@/views/inspection/ocurrence/EditOccurrence.vue"),
  },
  {
    path: "export-apipnr",
    name: "export-apipnr",
    component: () => import("@/views/inspection/ExportApipnr.vue"),
  },

  {
    path: "icon-action/list",
    name: "icon-action-list",
    component: () => import("@/views/inspection/icon-action/ListIconAction.vue"),
  },
  {
    path: "icon-action/add",
    name: "icon-action-add",
    component: () => import("@/views/inspection/icon-action/AddIconAction.vue"),
  },
  {
    path: "icon-action/edit/:id",
    name: "icon-action-edit",
    component: () => import("@/views/inspection/icon-action/EditIconAction.vue"),
  },
  {
    path: "tisba",
    name: "tisba",
    component: () => import("@/views/inspection/tisba/EditTisba.vue"),
    props: true,
  },
  {
    path: "inspectionsAndOccurencies",
    name: "inspections-and-occurencies",
    component: () => import("@/views/inspection/inspections-and-occurencies/InspectionsAndOccurrences.vue"),
  },
];
