export default [
  {
    path: "list",
    name: "list-updates",
    props: route => ({ enableEdit: route.query.enableEdit }),
    component: () => import("@/views/updates/ListUpdates.vue"),
  },
  {
    path: "edit",
    name: "edit-updates",
    component: () => import("@/views/updates/EditUpdates.vue"),
  }
];
